exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-activity-js": () => import("./../../../src/pages/activity.js" /* webpackChunkName: "component---src-pages-activity-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-sepro-athena-js": () => import("./../../../src/pages/sepro-athena.js" /* webpackChunkName: "component---src-pages-sepro-athena-js" */),
  "component---src-pages-sepro-erp-js": () => import("./../../../src/pages/sepro-erp.js" /* webpackChunkName: "component---src-pages-sepro-erp-js" */),
  "component---src-pages-sepro-kayloo-2-js": () => import("./../../../src/pages/sepro-kayloo2.js" /* webpackChunkName: "component---src-pages-sepro-kayloo-2-js" */),
  "component---src-pages-sepro-kayloo-js": () => import("./../../../src/pages/sepro-kayloo.js" /* webpackChunkName: "component---src-pages-sepro-kayloo-js" */),
  "component---src-pages-sepro-rh-js": () => import("./../../../src/pages/sepro-rh.js" /* webpackChunkName: "component---src-pages-sepro-rh-js" */),
  "component---src-pages-sepro-solution-js": () => import("./../../../src/pages/sepro-solution.js" /* webpackChunkName: "component---src-pages-sepro-solution-js" */),
  "component---src-pages-sepro-technology-js": () => import("./../../../src/pages/sepro-technology.js" /* webpackChunkName: "component---src-pages-sepro-technology-js" */)
}

